<template>
    <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'bplocation'"></app-add-button>
      </template>
         <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
            <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.loc_idno"
                    label="Location ID No"
                    :rules="loc_idnoRules"
                    required
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.bp_idno"
                    :items="data"
                    data-vv-name="businesspartners"
                    item-text="name"
                    item-value="id"
                    :rules="bp_idnoRules"
                    label="BP Name"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.loc_name"
                    label="Location Name"
                    :rules="loc_nameRules"
                    required
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.status"
                    :items="activeStatus"
                    :rules="[v => !!v || 'Please select Status']"
                    label="Status"
                    data-vv-name="activeStatus"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.address1"
                    label="Address 1"
                    required
                    :counter="200"
                    maxlength=200
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.address2"
                    label="Address 2"
                    maxlength=100
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.address3"
                    label="Address 3"
                    :counter="100"
                    maxlength=100
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.city"
                    :counter="50"
                    label="City"
                    maxlength=50
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.province"
                    :counter="50"
                    label="Province"
                    maxlength=50
                ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.phone1"
                    label="Phone 1"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.phone2"
                    label="Phone 2"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.phone3"
                    label="Phone 3"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.fax"
                    label="Fax"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.contact_person"
                    label="Contact Person"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.api_acct"
                    label="API Account"
                ></v-text-field> 
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.apz_acct"
                    label="APZ Account"
                ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.inbound"
                    label="Inbound"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.outbound"
                    label="Outbound"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.pc_equip"
                    label="PC Equip"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.full"
                    label="Full"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.atmtd_loc"
                    label="Atmtd Loc"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.con_date_from"
                    label="Date From"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field
                    v-model="editedItem.con_date_to"
                    label="Date To"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.peso_imt"
                    label="Peso IMT"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.peso_dmt"
                    label="Peso DMT"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.dollar_imt"
                    label="Dollar IMT"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.dollar_dmt"
                    label="Dollar DMT"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.dollar_po"
                    label="Dollar PO"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.ral_status"
                    label="RAL Status"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.sol_idno"
                    label="Sol ID Number"
                ></v-text-field>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: false,
		loc_idnoRules: [
			v => !!v || 'Location ID Number is required',
        
		],
		bp_idnoRules: [
			v => !!v || 'BP Name is required',
		],
		loc_nameRules: [
			v => !!v || 'Location Name is required',
			v => (v && v.length <= 100) || 'Location Name must be 100 characters or less.',
		],
		data: [],
		nextPage: ''
	}),

	computed: {
		...mapGetters({
			bplocations: 'bplocation/bplocations',
			businesspartners: 'businesspartner/businesspartners',
			activeStatus: 'bplocation/activeStatus',
			editedItem: 'bplocation/editedItem',
			defaultItem: 'bplocation/defaultItem',
			dialog: 'bplocation/dialog',
			editedIndex: 'bplocation/editedIndex',
			formTitle: 'bplocation/formTitle',
			valid: 'bplocation/valid'
		}),
	}, 
	watch: {
		businesspartners: {
			handler(){
				this.getData();
			}
		}
	},
	methods: {
		async getData(){
			this.data = this.businesspartners.data;
			this.nextPage = this.businesspartners.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('businesspartner/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		newEntry(){
			this.$store.dispatch('bplocation/setDialog',true);
			this.$store.dispatch('bplocation/setformTitle','New Entry');
			this.$store.dispatch('bplocation/setValid',false);
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('bplocation/setValid',true);
				this.$store.dispatch('bplocation/setDialog',false);
				this.$store.dispatch('bplocation/setEditedIndex','-1');
				this.isvalid = false;
			}, 3000);
		},
      
		save () { 
			this.$store.dispatch('bplocation/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('bplocation/updateBplocation',this.editedItem);
				} else {
					this.$store.dispatch('bplocation/saveBplocation',this.editedItem);
				}
				this.close();
			}
		},
	}
};
</script>