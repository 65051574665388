<template>
	<div class="home">
		<table-bplocation></table-bplocation>
	</div>
</template>

<script>
import tableBplocation from '@/components/pages/maint/bplocation/tableBplocation.vue';

export default {
	components: {
		'table-bplocation': tableBplocation,
	}
};
</script>

<style>

</style>
