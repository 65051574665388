<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="bplocations.data"
			sort-by="bp_locidno"
			class="elevation-1"
			dense
			:search="search"
			:items-per-page="1000"
		hide-default-footer
		>
			<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Business Partners Location File Maintenance</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
						<vue-excel-xlsx
							:data="bplocations.data"
							:columns="columnHeaders"
				:file-name="module_name"
							>
							<v-btn icon alt class="mt-2" :color="bplocations.data.length=== 0 ? 'gray' : 'primary'" :disabled="bplocations.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
						</vue-excel-xlsx>
					</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
					<v-icon
						color="grey"
						class="mr-2"
						small
						v-if="role_access < 3"
					>
						mdi-pencil-off
					</v-icon>
					<v-icon color="primary" small class="mr-2" @click="editItem(item)" v-else>edit</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
					<v-icon color="red" v-else small @click="deleteItem(item)">delete</v-icon>
			</template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(bplocations.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(bplocations.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{bplocations.current_page}} ( {{bplocations.from}} - {{bplocations.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!bplocations.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!bplocations.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
		</v-data-table>
		<create-bplocation v-if="role_access > 1"></create-bplocation>
	</div>
</template>

<script>
import createBplocation from './createBplocation.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-bplocation': createBplocation
	},
	data(){
		return{
			module_name: 'BP Locations',
			role_access: 1,
			search: ''
		};
	},
	computed: {
		...mapGetters({
			bplocations: 'bplocation/bplocations',
			headers: 'bplocation/headers',
			columnHeaders: 'bplocation/columnHeaders',
			editedItem: 'bplocation/editedItem',
			defaultItem: 'bplocation/defaultItem',
			dialog: 'bplocation/dialog',
			editedIndex: 'bplocation/editedIndex',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('bplocation/getBplocations');
		this.$store.dispatch('businesspartner/getBusinesspartners');
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async editItem (item) {
			this.$store.dispatch('bplocation/setEditedIndex',this.bplocations.data.indexOf(item));
			//this.$store.dispatch('bplocation/setEditedItems',Object.assign({}, item));
			await this.$store.dispatch('bplocation/getDetails', item.id);
			this.$store.dispatch('bplocation/setDialog',true);
			this.$store.dispatch('bplocation/setValid',true);
			this.$store.dispatch('bplocation/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.loc_idno + '(' + item.loc_name + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('bplocation/setEditedIndex', this.bplocations.data.indexOf(item));
					this.$store.dispatch('bplocation/deleteBplocation',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.bplocations.current_page + 1;
			this.$store.dispatch('bplocation/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.bplocations.current_page - 1;
			this.$store.dispatch('bplocation/getDataPages', prevPage);
		}
	}
};
</script>